import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { RegistroLayout } from '../layouts';
import { api } from '../services';

const Registro = () => {
  const [labels, setLabels] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const currentLang = useSelector(({ config }) => config.language);

  const { fetchLabelsCheckIn } = api();

  useEffect(() => {
    let ignore = false;
    setLoading(true);
    const fetchData = async ignore => {
      try {
        let response = null;
        response = await fetchLabelsCheckIn(currentLang);

        if (!ignore) {
          setLabels(response.labels);
          //   console.debug(response.labels);
        }
      } catch (e) {
        setError(e.message);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    fetchData(ignore);
    return () => {
      ignore = true;
    };
  }, []);
  return (
    <RegistroLayout
      labels={labels}
      loading={loading}
      error={error}
      currentLang={currentLang}
    />
  );
};

export default Registro;
